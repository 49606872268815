<template>
  <div class="uk-grid uk-grid-small uk-flex uk-flex-middle" data-uk-grid>
    <div class="uk-width-auto uk-text-small">
      Suchen
    </div>
    <slot :props="selectedFilters" :activate-filter="activateFilter"/>
    <div class="uk-width-auto uk-flex uk-flex-middle">
      <a href="#"
        @click.prevent="reset"
        data-uk-icon="icon:refresh;ratio:0.8"
        class="uk-link-reset uk-text-small"
        data-uk-tooltip="Filter zurücksetzen"
      ></a>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  data() {
    return {
      selectedFilters: {},
    }
  },

  mounted() {
    this.selectedFilters = _.omit(this.$route.query, ['page'])
  },

  methods: {
    reset() {
      this.selectedFilters = {}
      this.activateFilter()
    },
    activateFilter (key, value) {
      //only for href links
      //if(_.has(this.selectedFilters, key)) {
      //  this.selectedFilters =  _.omit(this.selectedFilters, key)
      //} else {
        this.selectedFilters[key] = value;
      //}

      this.selectedFilters = _(this.selectedFilters)
        .omitBy(_.isNull)
        .omitBy(_.isEmpty)
        .value();

      this.$router.replace({
        query: {
          ...this.selectedFilters,
          page: 1
        }
      })
    },
  }
}
</script>