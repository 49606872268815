<template>
  <div>
    <div class="uk-section uk-section-xsmall uk-padding-remove-top">
      <div
        data-uk-sticky="offset:52;media: @m"
          class="uk-background-default breadcrumb"
          style="padding: 15px 35px;border-bottom: 1px solid rgb(229, 229, 229);"
      >
        <div class="uk-flex uk-flex-between uk-flex-middle">
          <div class="uk-flex uk-flex-middle">
            <h1 class="uk-h5 uk-margin-remove uk-flex uk-flex-middle" style="color: rgb(51, 51, 51)">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor" class="uk-margin-small-right">
                <path
                  d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"
                />
              </svg>
              Kunden
            </h1>
          </div>
          <div>
            <router-link :to="{ name: 'customer-edit' }" class="uk-button uk-button-primary uk-button-small uk-border-rounded">
              <span uk-icon="plus-circle"></span>
              Neuen Kunden anlegen
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-container uk-margin-top">
      <div class="uk-grid uk-grid-small" data-uk-grid>
        <div class="uk-width-1-1">
          <div class="uk-card uk-card-default uk-margin-large-bottom">
            <div class="uk-card-body">
              <div class="uk-grid uk-grid-small uk-flex uk-flex-middle">
                <div class="uk-width-auto">
                  <search v-slot="{ props, activateFilter }">
                    <div class="uk-width-medium">
                      <input type="text" v-model="props.query" placeholder="Firma, Name, ..." @keyup.enter.prevent="activateFilter" class="uk-input uk-border-rounded" />
                    </div>
                  </search>
                </div>
              </div>
              <table class="uk-table uk-table-hover uk-table-striped uk-table-responsive" v-if="customers.length">
                <thead>
                  <tr>
                    <th class="uk-table-expand">Name</th>
                    <th class="uk-table-expand">Anschrift</th>
                    <th>Telefon</th>
                    <th class="uk-table-shrink"></th>
                    <th style="width: 60px"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="uk-visible-toggle" v-for="customer in customers" :key="customer.id">
                    <td>
                      <router-link
                        :to="{
                          name: 'customer-edit',
                          params: { id: customer.id },
                        }"
                      >
                        <span v-if="customer.company">
                          {{ customer.company }}
                        </span>
                        <span v-else> {{ customer.firstname }} {{ customer.lastname }} </span>
                      </router-link>
                    </td>
                    <td>
                      {{ customer.street }}, {{ customer.zipcode }}
                      {{ customer.city }}
                    </td>
                    <td>
                      {{ customer.phone }}
                    </td>
                    <td class="uk-text-right@m">
                      {{ new Date(customer.createdAt).toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' }) }}
                    </td>
                    <td class="uk-text-right@m">
                      <router-link
                        class="uk-icon-link uk-margin-small-right uk-text-primary"
                        data-uk-icon="pencil"
                        :to="{
                          name: 'customer-edit',
                          params: { id: customer.id },
                        }"
                      >
                      </router-link>
                      <a class="uk-icon-link uk-text-danger" data-uk-icon="trash" href="#" @click.prevent="remove(customer.id)"> </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-if="meta && meta.pagination.total_pages > 1">
                <pagination :pagination="meta.pagination"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CustomerService from '@/services/customer.service.js'
import 'izitoast/dist/css/iziToast.min.css'
import iziToast from 'izitoast/dist/js/iziToast.min.js'
import Search from '@/components/Search'
import Pagination from '@/components/Pagination'
export default {
  data() {
    return {
      customers: [],
      checked: [],
      allSelected: false,
      meta: null,
    }
  },
  components: {
    Search,
    Pagination,
  },
  mounted() {
    this.getCustomers()
  },
  watch: {
    '$route.query': {
      handler() {
        this.getCustomers()
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    getCustomers() {
      CustomerService.getList({ ...this.$route.query }).then((response) => {
        this.customers = response.data.data
        this.meta = response.data.meta
      })
    },
    remove(id) {
      const $this = this
      this.$uikit.modal
        .confirm('Kunde löschen?', {
          labels: {
            cancel: 'Abbrechen',
            ok: 'Löschen',
          },
        })
        .then(
          function () {
            CustomerService.delete(id).then(() => {
              iziToast.success({
                title: 'OK',
                message: 'Kunde gelöscht!',
                timeout: 3000,
                position: 'bottomRight',
                transitionIn: 'fadeInLeft',
              })
              $this.getCustomers()
            })
          },
          function () {
            console.log('Rejected.')
          }
        )
    },
  },
}
</script>
