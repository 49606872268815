import Api from './api.service'

export default {
  getList (params) {
    return Api().get('/customer', { params })
  },
  get (id) {
    return Api().get(`/customer/${id}`)
  },
  store (payload) {
    return Api().post('customer', payload)
  },
  update (id, payload) {
    return Api().post(`customer/${id}`, payload)
  },
  delete (id) {
    return Api().delete(`customer/${id}`)
  },
  updateByNumber(number, payload) {
    return Api().post(`customer/update-by-number/${number}`, payload)
  }
}
